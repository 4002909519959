<div class="container-fluid p-0">
  <div class="comingsoon">
    <div class="comingsoon-inner text-center"><img src="assets/images/logo/logo.svg" alt="">
      <h5>EM BREVE</h5>
      <div class="countdown" id="clockdiv">
        <ul>
          <li><span class="time digits" id="days"></span><span class="title">dias</span></li>
          <li><span class="time digits" id="hours"></span><span class="title">horas</span></li>
          <li><span class="time digits" id="minutes"></span><span class="title">minutos</span></li>
          <li><span class="time digits" id="seconds"></span><span class="title">segundos</span></li>
        </ul>
      </div>
    </div>
  </div>
</div>