import jwt_decode from "jwt-decode";
import { ManagerModel } from "./../models/manager.model";
import { DowingToken } from "./../domains/dowing-token.interface";
import { Constants } from "./../utils/constants";
import { Injectable } from "@angular/core";
import { Roles } from "../utils/enums/roles.enum";

@Injectable({
  providedIn: "root",
})
export class LocalStorageService {
  constructor() {}

  getDowingToken() {
    return (
      (JSON.parse(
        localStorage.getItem(Constants.DOWING_TOKEN)
      ) as DowingToken) || null
    );
  }

  getAccessToken() {
    return (localStorage.getItem(Constants.ACCESS_TOKEN) as string) || null;
  }

  setAccessToken(jwt) {
    localStorage.setItem(Constants.ACCESS_TOKEN, jwt);
  }

  getManager() {
    return (
      (JSON.parse(localStorage.getItem(Constants.DOWING_TOKEN))
        .manager as ManagerModel) || null
    );
  }

  getUser() {
    return (
      (JSON.parse(localStorage.getItem(Constants.DOWING_TOKEN))
        .manager as ManagerModel) || null
    );
  }

  getManagerId() {
    return (
      JSON.parse(localStorage.getItem(Constants.DOWING_TOKEN)).manager.id ||
      null
    );
  }

  getCompanyId() {
    return (
      JSON.parse(localStorage.getItem(Constants.DOWING_TOKEN)).manager.company
        .id || null
    );
  }

  setItem(key, value) {
    localStorage.setItem(key, value);
  }

  getItem(key) {
    return JSON.parse(localStorage.getItem(key));
  }
  setItemSession(key, value) {
    sessionStorage.setItem(key, value);
  }

  getItemSession(key) {
    return JSON.parse(sessionStorage.getItem(key));
  }

  clearLocalStorage() {
    localStorage.clear();
  }

  updateManager(manager: ManagerModel) {
    let dowingToken = this.getDowingToken();
    dowingToken.manager = manager;
    this.setItem(Constants.DOWING_TOKEN, JSON.stringify(dowingToken));
  }

  updateTokens(dowingToken: any) {
    this.setItem(Constants.DOWING_TOKEN, JSON.stringify(dowingToken));
    this.setItem(
      Constants.ACCESS_TOKEN,
      JSON.stringify(dowingToken.access_token)
        .split("")
        .filter((x) => x != '"')
        .join("")
    );
  }

  isMainManager() {
    let token = this.getAccessToken();
    let decoded = jwt_decode(token) as any;
    return decoded.role == Roles.MainManager;
  }

  setTeam(team) {
    this.setItem(Constants.CURRENT_TEAM, JSON.stringify(team));
  }

  setEmployee(employee) {
    this.setItem(Constants.CURRENT_EMPLOYEE, JSON.stringify(employee));
  }

  getTeam() {
    return this.getItem(Constants.CURRENT_TEAM);
  }

  getEmployee() {
    return this.getItem(Constants.CURRENT_EMPLOYEE);
  }
}
