import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "./shared/services/auth/auth.guard";
import { AdminGuard } from "./shared/services/auth/admin.guard";

const routes: Routes = [
  { path: "", redirectTo: "/home", pathMatch: "full" },
  {
    path: "login",
    loadChildren: () =>
      import("./pages/auth/auth.module").then((m) => m.AuthModule),
  },
  {
    path: "admin",
    canActivate: [AdminGuard],
    loadChildren: () =>
      import("./pages/admin/admin.module").then((m) => m.AdminModule),
  },
  {
    path: "user",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./pages/user/user.module").then((m) => m.UserModule),
  },
  {
    path: "home",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./pages/user/home/home.module").then((m) => m.HomeModule),
  },
  {
    path: "final-user",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./pages/final-user/final-user.module").then(
        (m) => m.FinalUserModule
      ),
  },
  { path: "**", redirectTo: "/home" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
