import { BrowserModule } from "@angular/platform-browser";
import { LOCALE_ID, NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastrModule } from "ngx-toastr";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ComingSoonModule } from "./pages/coming-soon/coming-soon.module";

import { AuthService } from "./shared/services/auth/auth.service";
import { AuthInterceptor } from "./shared/services/auth/auth.interceptor";
import { UseTermsComponent } from "./pages/common/use-terms/use-terms.component";
import { GeneralConditionsComponent } from "./pages/common/general-conditions/general-conditions.component";

import { registerLocaleData } from "@angular/common";
import localePt from "@angular/common/locales/pt";
import { NgbDateParserFormatter } from "@ng-bootstrap/ng-bootstrap";
import { NgbDateFRParserFormatter } from "src/environments/framework/ngb-date-fr-parser-formatter";

registerLocaleData(localePt);

@NgModule({
  declarations: [AppComponent, UseTermsComponent, GeneralConditionsComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ComingSoonModule,
    HttpClientModule,
    ToastrModule.forRoot(),
  ],
  providers: [
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: "pt" },
    { provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
