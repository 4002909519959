import { Component, OnDestroy, OnInit } from "@angular/core";


@Component({
  selector: "app-simple",
  templateUrl: "./simple.component.html",
  styleUrls: ["./simple.component.scss"],
})
export class SimpleComponent implements OnInit, OnDestroy {
  seconds: number;
  setIntervalId;
  constructor() {
    this.setTime();
  }

  setTime() {
    this.setIntervalId = setInterval(function () {
      var countDown = new Date("Jul 19, 2021 00:00:00").getTime();
      var now = new Date().getTime();
      var distance = countDown - now;
      this.document.getElementById("days").innerHTML = Math.floor(
        distance / (1000 * 60 * 60 * 24)
      );
      this.document.getElementById("hours").innerHTML = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      this.document.getElementById("minutes").innerHTML = Math.floor(
        (distance % (1000 * 60 * 60)) / (1000 * 60)
      );
      this.document.getElementById("seconds").innerHTML = Math.floor(
        (distance % (1000 * 60)) / 1000
      );
    }, this.seconds);
  }

  ngOnInit() { }

  ngOnDestroy() {
    clearInterval(this.setIntervalId)
  }
}
