import { Roles } from './../../utils/enums/roles.enum';
import { Injectable } from "@angular/core";
import jwt_decode from "jwt-decode";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { LocalStorageService } from "../local-storage.service";

@Injectable({
  providedIn: "root",
})
export class AdminGuard implements CanActivate {
  constructor(
    private router: Router,
    private localStorageService: LocalStorageService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const token = this.localStorageService.getAccessToken();
    let decoded = jwt_decode(token) as any;
    if (decoded.role == Roles.BackofficeUser) {
      return true;
    }

    // not logged in so redirect to login-template page with the return url
    this.router.navigate(["/login"], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
