import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { APIVersion } from "src/app/shared/utils/enums/api-version.enum";

@Injectable({
  providedIn: "root",
})
export class BaseService {
  private BASE_URL = environment.baseUrl;

  constructor(private http: HttpClient) {}

  get(url: string, version: APIVersion = APIVersion.v1_1): Observable<any> {
    return this.http.get(`${this.getURLAPI(version)}/${url}`);
  }

  post(
    url: string,
    body: any,
    version: APIVersion = APIVersion.v1_1
  ): Observable<any> {
    return this.http.post(`${this.getURLAPI(version)}/${url}`, body);
  }

  put(
    url: string,
    body: any,
    version: APIVersion = APIVersion.v1_1
  ): Observable<any> {
    return this.http.put(`${this.getURLAPI(version)}/${url}`, body);
  }

  putV2(
    url: string,
    body: any,
    version: APIVersion = APIVersion.v1_2
  ): Observable<any> {
    return this.http.put(`${this.getURLAPI(version)}/${url}`, body);
  }

  patch(
    url: string,
    body: any,
    version: APIVersion = APIVersion.v1_1
  ): Observable<any> {
    return this.http.patch(`${this.getURLAPI(version)}/${url}`, body);
  }

  delete(url: string, version: APIVersion = APIVersion.v1_1): Observable<any> {
    return this.http.delete(`${this.getURLAPI(version)}/${url}`);
  }

  private getURLAPI(apiVersion: APIVersion) {
    return `${this.BASE_URL}/${apiVersion}`;
  }
}
