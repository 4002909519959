import { LocalStorageService } from "./../local-storage.service";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { APIVersion } from "../../utils/enums/api-version.enum";
import { BaseService } from "../base.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { map } from "rxjs/operators";
import { BehaviorSubject } from "rxjs";
import { JwtHelperService } from "@auth0/angular-jwt";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  public showLoader = false;
  public clearPassword = new BehaviorSubject(false);
  public helper = new JwtHelperService();

  constructor(
    private baseService: BaseService,
    private http: HttpClient,
    private router: Router,
    private localStorageService: LocalStorageService
  ) {}

  public refreshToken() {
    const auth = this.localStorageService.getDowingToken();
    let token = this.localStorageService.getAccessToken();
    let decodedToken = this.helper.decodeToken(token);
    let email = decodedToken.unique_name;

    const headers = new HttpHeaders({
      Authorization: `Bearer ${
        auth && auth.refresh_token ? auth.refresh_token : ""
      }`,
    });

    return this.http
      .get(
        `${environment.baseUrl}/v1.1/ManagerUser/refresh-token?email=${email}`,
        { headers }
      )
      .pipe(
        map((response) => {
          const authenticatedModel = response;
          this.localStorageService.updateTokens(authenticatedModel);
          return response;
        })
      );
  }

  public getRoleByLogin(email, password) {
    let loginModel = { email, password };
    return this.baseService.post(
      "Login/Authenticate",
      loginModel,
      APIVersion.v1_2
    );
  }

  public loginUser(email, password) {
    let loginModel = { email, password };
    return this.baseService.post(
      "User/authenticate",
      loginModel,
      APIVersion.v1_2
    );
  }

  public loginManager(email, password) {
    let loginModel = { email, password };
    return this.baseService.post(
      `ManagerUser/authenticate`,
      loginModel,
      APIVersion.v1_2
    );
  }

  public loginAdmin(email, password) {
    let loginModel = { email, password };
    return this.baseService.post(
      "Backoffice/authenticate",
      loginModel,
      APIVersion.v1_1
    );
  }

  public newPassword(email, password) {
    let loginModel = { email, password };
    return this.baseService.post(
      "ManagerUser/authenticate",
      loginModel,
      APIVersion.v1_1
    );
  }

  public requestChangePassword(email) {
    return this.baseService.post(
      `ManagerUser/request-change-password/${email}`,
      {},
      APIVersion.v1_1
    );
  }

  public confirmChangePassword(newPassword) {
    return this.baseService.post(
      `ManagerUser/confirm-change-password`,
      newPassword,
      APIVersion.v1_1
    );
  }

  public logout() {
    this.localStorageService.clearLocalStorage();
    this.router.navigateByUrl("/login");
  }

  public isTokenExpired(tokenExpirationDate: Date): boolean {
    const now = new Date();
    const expiration = new Date(tokenExpirationDate);
    return now > expiration;
  }
}
